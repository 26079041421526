<template>

    <div class="row">

        <div class="col-md-1">&nbsp;</div>

        <div id="centered-content" class="col-xs-12 col-md-10" style="background-color: #464646;">

            <div class="col-md-12 top-section">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-1">&nbsp;</div>
                            <div class="col-xs-12 col-md-11" style="min-height: 200px; margin-left: 1em;">
                                <h1 style="text-align:left">{{ msg }}</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">&nbsp;</div>
                            <div class="col-xs-12 col-md-3" style="margin-left: 1em;">
                                Are you ready to take your <br /> smoking sessions to new heights?
                            </div>
                            <div class="col-xs-12 col-md-7" style="margin-left: 1em;">
                                <a href="https://www.ebay.com/itm/375120287108?mkcid=16&mkevt=1&mkrid=711-127632-2357-0&ssspo=4n07_jgftm6&sssrc=2524149&ssuid=4n07_jgftm6&var=&widget_ver=artemis&media=COPY"><button class="btn btn-primary" v-onclick>ORDER HERE!</button></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                </div>
            </div>

            <div class="row image-header">

                <img src="./mod_1.jpg" class="product-image" />

                <div class="image-header-details">
                    <h2>Introducing the Ultimate Smoking Experience: The 48-Inch, 7mm Thick Bong!</h2>
                    <div class="col-md-12 details-box-corner-border">
                        Limited Time Offer: Act now to get your hands on this extraordinary 48-inch, 7mm thick bong at a special price. Don't miss out on the chance to own a piece of smoking history.
                        <br /><br />

                        Are you ready to take your smoking sessions to new heights? Look no further than our incredible 48-inch, 7mm thick bong. Crafted for true connoisseurs of smoking, this masterpiece is designed to elevate your experience in every way possible.
                        <br /><br />

                        Experience the difference for yourself - upgrade your smoking game with the 48-Inch, 7mm Thick Bong today! Elevate your sessions to the next level and make a lasting impression. Order now, and savor the ultimate smoking adventure.
                    </div>
                </div>

            </div>

            <div class="row">

                <div id="first-detail" class="col-md-4 details-box" style="background-color: #ade4e7; color: #3599d5; margin:0px;">
                    Premium Quality: Crafted with the highest-grade borosilicate glass, this bong is built to last. You'll enjoy years of satisfying sessions without worrying about breakage.
                </div>
                <div class="col-md-4 details-box" style="background-color: #121b3c; color: #3599d5; margin: 0px; ">
                    Unrivaled Smoothness: Our bong's 7mm thickness guarantees exceptional durability and provides a super smooth hit every time. Say goodbye to harshness and hello to pure enjoyment.
                </div>
                <div class="col-md-4 details-box" style="background-color: #ade4e7; color: #3599d5; margin: 0px; ">
                    Perfect for Parties: Impress your friends at gatherings and parties with this showstopper. It's not just a bong; it's a conversation starter.
                </div>

            </div>

            <div class="row">

                <div class="col-md-4 details-box" style="background-color: #121b3c; color: #3599d5; margin: 0px; ">
                    Sleek and Elegant Design: The 48-inch height not only delivers massive hits but also makes a bold statement in any setting. Its sleek design will be the centerpiece of your collection.
                </div>
                <div class="col-md-4 details-box" style="background-color: #ade4e7; color: #3599d5; margin: 0px; ">
                    Effortless Cleaning: Cleaning is a breeze thanks to its user-friendly design. You'll spend less time maintaining and more time enjoying.
                </div>
                <div class="col-md-4 details-box" style="background-color: #121b3c; color: #3599d5; margin: 0px; ">
                    Enhanced Smoking Pleasure: The large chamber allows for exceptional smoke filtration, delivering a cleaner, cooler, and more enjoyable hit every time.
                </div>

            </div>

            <div class="row image-header">

                <img src="./mod_2.jpg" class="" />

            </div>

            <div class="col-md-12 bottom-section">
                Disclaimer: This product is intended for use with legal smoking herbs only. You must be of legal smoking age to purchase.
                <br /><br />
                <a href="https://www.freepik.com/free-photo/abstract-space-wallpaper-background-dark-smoke-design_17581399.htm#query=smoke&position=22&from_view=search&track=sph">Image by rawpixel.com</a> on Freepik
                <br />
            </div>

        </div>

        <div class="col-md-1">&nbsp;</div>

    </div>

</template>

<script>
    import { ref, reactive } from 'vue';
    import state from '@/state';
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        },
        methods: {
            span(text) {
                return `<span> ${text} </span>`
            },
            getSlide(name, slideNumber, captionText) {
                //var imagePath = new URL(name);
                return `<div class="numbertext">${slideNumber} / 3</div>
                        <img :src="{ url: require(${name})}" style="width:100%">
                        <div class="text">${captionText}</div>`;
            },
            roundToPercent(number) {
                var temp = Math.round(number * 100);
                return temp;
            },
            round(number) {
                var temp = Math.round(number * 1000)/1000;
                return temp;
            },
            sizeDetails() {
                let cornerBorders = document.getElementsByClassName('details-box-corner-border');
                let abilityHeader = document.getElementsByClassName('ability-header');
                let details = document.getElementsByClassName('details-box');
                var largestMinHeight = 0;
                var largestCornerBorder = 0;
                for (var i = 0; i < cornerBorders.length; i++) {
                    console.log(cornerBorders[i].offsetHeight)
                    if (cornerBorders[i].offsetHeight >= largestCornerBorder)
                        largestCornerBorder = cornerBorders[i].offsetHeight;
                }
                console.log('Largest Height: ' + largestMinHeight);
                /*
                for (var i = 0; i < details.length; i++) {
                    console.log(details[i].offsetHeight)
                    if (details[i].offsetHeight >= largestMinHeight)
                    largestMinHeight = details[i].offsetHeight;
                }
                console.log('Largest Corner Height: ' + largestCornerBorder);
                */
                for (var i = 0; i < details.length; i++) {
                    console.log(details[i].style.minHeight);
                    details[i].style.minHeight = largestMinHeight + 'px';
                    console.log(details[i].style.minHeight);
                }
                /*
                for (var i = 0; i < cornerBorders.length; i++) {
                    console.log(cornerBorders[i].style.minHeight);
                    cornerBorders[i].style.minHeight = largestCornerBorder + 'px';
                    console.log(cornerBorders[i].style.minHeight);
                }
                
                var totalHeight = (largestMinHeight * 4) + abilityHeader[0].offsetHeight;
                let imageCarousel = document.getElementsByClassName('slideshow-image');
                for (var a = 0; a < imageCarousel.length; a++) {
                    imageCarousel[a].style.maxHeight = totalHeight + 'px';
                }
                */
            },
        },
        mounted: function () {
            console.log("in updated");
            this.sizeDetails();
        },
        updated: function () {
            console.log("in updated");
            this.sizeDetails();
        },
        setup(methods) {
            const model = reactive(state.viewModel);
            //model.showSlides(1);



            return {
                model,
                methods,
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style: inside;
        list-style-type: disc !important;
        padding-left: 1em !important;
        margin-left: 1em;
    }

    li {
        display: inline-block;
        margin: 0 10px;
        list-style-type: disc !important;
    }

    a {
        color: #121b3c;
    }

    #centered-content, row {
        padding: 0px !important;
        margin: 0px;
    }

    /*
    .details-box {
        text-align: left;
        border: 3px solid goldenrod;
        border-radius: 25px;
        padding: 10px;
        margin: 0px;
        background-color: #D2ECCD;
        background-color: #E4FFDE;
        color: #055215;
    }
    */

    .details-box {
        text-align: justify-all;
        padding: 20px 40px 20px 40px;
        margin: 0px 0px 0px 0px;
        color: #005fd7;
    }

    .details-box-corner-border {
        /*
        width: 100px;
        height: 100px;
            */
        background: linear-gradient(to right, #121b3c 4px, transparent 4px) 0 0, linear-gradient(to right, #121b3c 4px, transparent 4px) 0 100%, linear-gradient(to left, #121b3c 4px, transparent 4px) 100% 0, linear-gradient(to left, #121b3c 4px, transparent 4px) 100% 100%, linear-gradient(to bottom, #121b3c 4px, transparent 4px) 0 0, linear-gradient(to bottom, #121b3c 4px, transparent 4px) 100% 0, linear-gradient(to top, #121b3c 4px, transparent 4px) 0 100%, linear-gradient(to top, #121b3c 4px, transparent 4px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        padding: 2em;
    }

    .top-section {
        background:url('smoke-design.jpg') no-repeat center bottom;
        background-size: cover;
        min-height: 600px;
        text-align: left;
    }

        .top-section h1 {
            text-align: left;
            color: #005fd7;
            font-weight: bold;
            vertical-align: middle;
            margin-top: 60px;
            /*background-color: #fff;*/
            backdrop-filter:grayscale();
            padding: 10px;
        }

    .wrap {
        display: inline-block;
        background-color: #000;
    }

    .image-header-details {
        display: inline-block;
        /*width: 60%;*/
        vertical-align: middle !important;
        padding: 4em;
        color: #3599d5;
        text-align: justify;
    }

    .mid-section {
        color: #121b3c;
    }

        .mid-section h1, h2 {
            /*color: #005fd7;*/
            color: #ade4e7;
            margin-bottom: 1em;
        }

    .bottom-section {
        /*
        background-color: #53a0ca;
            */
        padding: 20px;
    }

    .product-image {
        /*
        max-height: 1000px;
        max-width: 500px;
            */
        display: inline-block;
        /*width: 40%;*/
        vertical-align: middle;
    }

    @media screen and (max-width: 500px) {
        .product-image {
            width: 99%;
            height: auto;
        }
        .image-header-details {
            width: 99%;
            height: auto;
        }
    }

    @media (min-width: 1800px) {
        .product-image {
            width: 40%;
            height: auto;
        }

        .image-header-details {
            width: 60%;
            height: auto;
        }
    }

</style>
