export default class ViewModel {
    champions = [];
    selectedChampions = [];
    searchName = '';
    searchSkillDescription = '';
    searchTraits = '';
    slideIndex = 1;

    showSlides(n) {
        console.log("n: " + n);
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot");
        if (slides.length > 0 && dots.length > 0) {
            if (n > slides.length) { this.slideIndex = 1 }
            if (n < 1) { this.slideIndex = slides.length }
            console.log(this.slideIndex);
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            console.log("slides["+(this.slideIndex-1)+"]")
            slides[this.slideIndex - 1].style.display = "block";
            dots[this.slideIndex - 1].className += " active";
        }
    }

    // Next/previous controls
    plusSlides(n) {
        console.log("direction: " + n);
        this.showSlides(this.slideIndex += n);
    }

    // Thumbnail image controls
    currentSlide(n) {
        this.showSlides(this.slideIndex = n);
    }
}