<template>
  <!--<img id="logo" alt="logo" src="./assets/logo.png">-->
  <HelloWorld msg="Ultimate Smoking Experience" />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #ade4e7;
        margin-top: 0px;
        background-color: #000;
        /*background-color: #31967D;*/
        /*background-color: teal;*/
        min-height: 800px;
        /*max-width: 1200px;*/
    }

    html {
        background-color: #000;
        color: #ade4e7;
        align-items: center;
        text-align:center;
    }

    #logo {
        max-height: 200px;
    }

    @import'~bootstrap/dist/css/bootstrap.css';
</style>
